import React from 'react';
import { loadStripe } from '@stripe/stripe-js';

// Load Stripe with your publishable key
// const stripePromise = loadStripe('pk_test_51QQ5DfK9FsSHWtjJwCI8Cm6zwkEUNniQwJQ4wRfaiIqxaaA58clXtgaOB89TLtQUPTa1llVgsyQHzGPpTiKwvzKb004lfUMhRi');

const stripePromise = loadStripe('pk_live_51QQ5DfK9FsSHWtjJC5JVpsp4ZSdg1i6T6GksPbLEowu0yTG5mC4Mg5PX9gZPe33nCpomQserQXS0cF64nvn7KPrO00OADC4BuT');

const CheckoutButton = ({ uid, buttonClass, buttonText }) => {
  const handleCheckout = async () => {
    const stripe = await stripePromise;

    const product = buttonText.includes("Monthly") ? "price_1QeMl1K9FsSHWtjJmJIPVNLj" : "price_1QeMm0K9FsSHWtjJMuzfQU2K";

    // Redirect to Stripe Checkout with the Price ID
    const { error } = await stripe.redirectToCheckout({
      lineItems: [
        {
          price: product, // Replace with your correct Price ID
          quantity: 1,
        },
      ],
      mode: 'subscription', // or 'subscription' for recurring payments
      successUrl: 'https://app.mrcharliebot.com/paymentsuccess',
      cancelUrl: 'https://app.mrcharliebot.com',
      clientReferenceId: uid, // Pass the UID
    });

    if (error) {
      console.error('Stripe Checkout error:', error);
    }
  };

  return (
    <button className={buttonClass} onClick={handleCheckout}>
      {buttonText}
    </button>
  );
};

export default CheckoutButton;
