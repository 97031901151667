import React, { useState } from 'react';

import app from "../firebase"; // Assuming Firebase is initialized here
import { httpsCallable, getFunctions } from 'firebase/functions';

const FreeAccessCode = () => {
    const [code, setCode] = useState('');
    const [message, setMessage] = useState('');
    const [isChecking, setIsChecking] = useState(false);

    const handleSubmit = async (e) => {
       
        e.preventDefault();


        const functions = getFunctions(app);
        const charlieBotFreeAccess = httpsCallable(functions, 'accessCode');
        setIsChecking(true);
        setMessage('');

        try {
            const result = await charlieBotFreeAccess({ code });
            setMessage(result.data.message || 'Access granted!');
        } catch (error) {
            setMessage(error.message || 'An error occurred while processing your request.');
        } finally {
            setIsChecking(false);
        }
    };

    return (
        <div style={{ textAlign: "center", padding: "5rem" }}>
            <img 
                style={{ height: "20vh" }} 
                src="https://jaj.dfq.mybluehost.me/website_5857b8b4/wp-content/uploads/2024/12/Copy-of-Charlie-Bot.png" 
                alt="Charlie Bot Logo" 
            />
            <br />
            <h3 className="mb-6 display-6 text-primary">
                Testing access for teachers and journalists
            </h3>
            <h5 className="mb-10 display-10 text-white">
                If we have given you an access code, enter below for free access to Mr. Charlie Bot
            </h5>

            <form onSubmit={handleSubmit} className="mx-auto mt-4" style={{ maxWidth: "600px" }}>
                <div className="mb-4">
                    <input
                        type="text"
                        className="form-control form-control-lg"
                        placeholder="Enter your access code"
                        value={code}
                        onChange={(e) => setCode(e.target.value)}
                        style={{ fontSize: "1.5rem", padding: "1rem" }}
                    />
                </div>
                <button 
                    type="submit" 
                    className="btn btn-primary btn-lg w-100" 
                    style={{ fontSize: "1.25rem", padding: "0.75rem" }}
                    disabled={isChecking}
                >
                    Submit Code
                </button>
            </form>

            {isChecking && (
                <p className="mt-4 text-info" style={{ fontSize: "1.25rem" }}>Checking the code...</p>
            )}

            {message && (
                <p className="mt-4 text-white" style={{ fontSize: "1.25rem" }}>{message}</p>
            )}
        </div>
    );
};

export default FreeAccessCode;
