import React, { useState, useEffect } from "react";
import { getDatabase, ref, get, update } from "firebase/database";
import { getFunctions, httpsCallable } from "firebase/functions";

const ManageSubscription = ({ closeSubscriptionManagement, user }) => {
  const [subscriptionData, setSubscriptionData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [confirmation, setConfirmation] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);

  useEffect(() => {
    const database = getDatabase();
    const subscriptionRef = ref(database, `users/${user.uid}/subscriptions/charlieBotSubscription`);

    const fetchData = async () => {
      try {
        const snapshot = await get(subscriptionRef);
        const data = snapshot.val();
        setSubscriptionData(data);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchData();
  }, [user.uid]);

  const handleCancelSubscription = async () => {
    try {
      const functions = getFunctions();
      const cancelSubscription = httpsCallable(functions, "cancelSubscription");

      // Call the cloud function to cancel the subscription in Stripe
      const response = await cancelSubscription({
        uid: user.uid,
        subscriptionId: subscriptionData.subscriptionId,
      });

      if (response.data.success) {
        // Update the subscription status in the Firebase database
        const database = getDatabase();
        const subscriptionRef = ref(database, `users/${user.uid}/subscriptions/charlieBotSubscription`);

        await update(subscriptionRef, {
          cancelAtPeriodEnd: true, 
        });

        setSuccessMessage("Your subscription will end when this month's membership expires.");
        setConfirmation(false);
        setSubscriptionData((prevData) => ({
          ...prevData,
          cancelAtPeriodEnd: true,
          currentPeriodEnd: response.data.currentPeriodEnd,
        }));
      } else {
        throw new Error(response.data.message || "Failed to cancel the subscription.");
      }
    } catch (error) {
      setError(error.message);
    }
  };

  if (loading) {
    return <div className="text-center mt-4">Loading subscription details...</div>;
  }

  if (error) {
    return <div className="alert alert-danger text-center">{error}</div>;
  }

  return (
    <div className="modal show d-block" tabIndex="-1" role="dialog">
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Manage Subscription</h5>
            <button type="button" className="close" onClick={closeSubscriptionManagement}>
              <span>&times;</span>
            </button>
          </div>
          <div className="modal-body">
            {successMessage && <div className="alert alert-success text-center">{successMessage}</div>}

            {subscriptionData?.cancelAtPeriodEnd ? (
              <div className="text-center">
                <p>Your subscription is already set to cancel at the end of the current billing cycle.</p>
              </div>
            ) : !confirmation ? (
              <div className="text-center">
                <p>Your Mr. Charlie Bot membership will end at your next billing cycle. Are you sure you want to end your membership?</p>
                <button
                  className="btn btn-danger"
                  onClick={() => setConfirmation(true)}
                >
                  Cancel Subscription
                </button>
              </div>
            ) : (
              <div className="text-center">
                <p>Click confirm to finalize cancellation. Your subscription will remain active until the end of the current billing cycle.</p>
                <button
                  className="btn btn-secondary mr-2"
                  onClick={() => setConfirmation(false)}
                >
                  Go Back
                </button>
                <button
                  className="btn btn-danger"
                  onClick={handleCancelSubscription}
                >
                  Confirm Cancellation
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageSubscription;
