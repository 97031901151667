import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { getDatabase, ref as databaseRef, onValue } from "firebase/database";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMessage,
  faBookOpen,
  faCirclePlay,
  faPlus,
  faRocket,
  faComments,
  faStar
} from "@fortawesome/free-solid-svg-icons";
import AudioChat from "./audioChat.js";
import StudentNavBar from "../student/navBar.js";
import { Link } from 'react-router-dom';

const CharlieMainMenu = ({ user }) => {
  const [conversations, setConversations] = useState([]);
  const [isChatVisible, setIsChatVisible] = useState(false);
  const [activeConversationId, setActiveConversationId] = useState(null);

  useEffect(() => {
    if (!user?.uid) return;

    const db = getDatabase();
    const userConversationsRef = databaseRef(db, `conversations/${user.uid}`);

    // Fetch conversations from Firebase
    const unsubscribe = onValue(userConversationsRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        // Filter out conversations with type === "task" and transform data into an array format
        const conversationsList = Object.keys(data)
          .filter((id) => data[id].type !== "task") // Exclude "task" types
          .map((id) => ({
            id,
            title: data[id].conversationHistory[0]?.content || "Untitled",
          }));
    
        setConversations(conversationsList);
      } else {
        setConversations([]);
      }
    });

    // Cleanup listener on unmount
    return () => unsubscribe();
  }, [user?.uid]);

  const handleNewConversation = () => {
    setActiveConversationId(null); // New conversation has no ID
    setIsChatVisible(true); // Show AudioChat
  };

  const handleConversationClick = (conversationId) => {
    setActiveConversationId(conversationId); // Set ID of selected conversation
    setIsChatVisible(true); // Show AudioChat
  };

  const handleCloseChat = () => {
    setIsChatVisible(false); // Hide AudioChat
    setActiveConversationId(null); // Reset conversation ID
  };

  if (isChatVisible) {
    return (
      <AudioChat
        user={user}
        conversationIdProps={activeConversationId}
        onClose={handleCloseChat}
      />
    );
  }

  const resources = [
    { name: "Reflection protocols", link: "https://drive.google.com/file/d/1BSz1yvmN4vnEMNz1_rpb_Y7KhWJoWVuF/view?usp=sharing" },
    { name: "Reflection conversations", link: "https://drive.google.com/file/d/1HAFvup4FcxbWP5-F8u--pOAfwN590vBZ/view?usp=sharing" },
    { name: "Study tips conversations", link: "https://drive.google.com/file/d/16zwfRK8W-rtwnk-nZRl90Ra59QZDj1Cp/view?usp=sharing" },
    { name: "Developing opinions on global issues", link: "https://drive.google.com/file/d/1Q-KdZ3uwMX53GnSPBBHAe-UiiUgK1LI4/view?usp=sharing" },
    { name: "Developing academic interests and enquiry", link: "https://drive.google.com/file/d/18xR68HpqcZgGPBLIK-nK4QmMCYUU7pZC/view?usp=sharing" },
    { name: "Body life area conversations", link: "https://drive.google.com/file/d/1X4gjUFvEbCXWR6SkY9hVD8wziLzNg8AO/view?usp=sharing" },
  ]

  return (
    <div style={{ maxHeight: "100vh" }}>
  <div className="container py-4">
    <StudentNavBar user={user} schoolData={{ path: "mrCharlieBot", name: "mrcharliebot" }} charliebot={true} />

    {/* Main Content */}
    <div className="row g-4" style={{ maxHeight: "75vh" }}>
      {/* Conversations Section */}
      <div className="col-md-6 d-flex flex-column">
        <div className="bg-white rounded-3 shadow p-4 d-flex flex-column flex-grow-1">
          <div className="d-flex justify-content-between align-items-center">
            <h2 className="fs-5 fw-bold text-dark d-flex align-items-center gap-2">
              <FontAwesomeIcon icon={faMessage} className="text-primary" />
              My Conversations
            </h2>
            {conversations.length > 0 && (
              <button
                className="btn btn-primary d-flex align-items-center gap-2"
                onClick={handleNewConversation}
              >
                <FontAwesomeIcon icon={faPlus} />
                <span>New Chat</span>
              </button>
            )}
          </div>
          <div
            style={{
              maxHeight: "65vh", // Adjusted to account for header height
              overflowY: "auto",
              scrollBehavior: "smooth",
              scrollbarWidth: "thin",
              scrollbarColor: "#007bff white",
            }}
            className="mt-3"
          >
            {conversations.length > 0 ? (
              conversations.slice().reverse().map((conv) => (
                <div
                  key={conv.id}
                  className="d-flex align-items-center p-3 mb-3 bg-light rounded shadow-sm position-relative"
                  onClick={() => handleConversationClick(conv.id)}
                  style={{ cursor: "pointer" }}
                >
                  <div className="flex-grow-1">
                    <h3 className="fs-6 fw-semibold text-dark">{conv.title}</h3>
                  </div>
                  <FontAwesomeIcon icon={faCirclePlay} className="text-primary fs-5" />
                </div>
              ))
            ) : (
              <div className="text-center">
                <p className="text-muted">Try looking at some resources first, then:</p>
                <button
                  className="btn btn-primary d-flex align-items-center gap-2 mt-2"
                  onClick={handleNewConversation}
                >
                  <FontAwesomeIcon icon={faPlus} />
                  <span>Start a Conversation</span>
                </button>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Resources Section */}
      <div className="col-md-6 d-flex flex-column">
        <div className="bg-white rounded-3 shadow p-4 mb-3">
          <h2 className="fs-5 fw-bold text-dark d-flex align-items-center gap-2">
            <FontAwesomeIcon icon={faRocket} className="text-primary" />
            Try some upcoming features
          </h2>
          <div className="row">
            <div className="col-md-6">
              <Link className="btn btn-primary d-flex align-items-center gap-2 mt-2" to={"/games"}>
                <FontAwesomeIcon icon={faStar} />
                <span>Games</span>
              </Link>
            </div>
            <div className="col-md-6">
              <Link className="btn btn-primary d-flex align-items-center gap-2 mt-2" to={"/tasks"}>
                <FontAwesomeIcon icon={faComments} />
                <span>Tasks</span>
              </Link>
            </div>
          </div>
        </div>
        <div className="bg-white rounded-3 shadow p-4 d-flex flex-column flex-grow-1">
          <h2 className="fs-5 fw-bold text-dark d-flex align-items-center gap-2">
            <FontAwesomeIcon icon={faBookOpen} className="text-primary" />
            Learning Resources
          </h2>
          <div
            className="mt-3"
            style={{
              maxHeight: "50vh", // Adjusted for header height
              overflowY: "auto",
              scrollBehavior: "smooth",
              scrollbarWidth: "thin",
              scrollbarColor: "#007bff white",
            }}
          >
            {resources.map((res) => (
              <a
                key={res.name}
                href={res.link}
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "none" }}
              >
                <div
                  className="d-flex align-items-center p-3 mb-3 bg-light rounded shadow-sm position-relative"
                  style={{ cursor: "pointer" }}
                >
                  <div className="flex-grow-1">
                    <h3 className="fs-6 fw-semibold text-dark">{res.name}</h3>
                  </div>
                </div>
              </a>
            ))}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


  );
};

CharlieMainMenu.propTypes = {
  user: PropTypes.shape({
    uid: PropTypes.string.isRequired,
  }).isRequired,
};

export default CharlieMainMenu;
