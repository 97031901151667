import React from "react";
import { Formik, Field, Form } from "formik";
import { getDatabase, ref, set, onValue, update } from "firebase/database";
import app from "../../firebase.js";
import AreaSteps from "./areaSteps.js";
import Characteristic from "./characteristic.js";

class Characteristics extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            mark: null,
            progressEstimate: 0,
            autoSavedValues: null,
            valuesChanged: false,
            databaseValues: {},
            errorSaving: false,
            loaded: false,
            initialValues: {},
            visibleStep: 0,
            loadError: false,
            possibleOffline: false // indicates we haven't received Firebase response in time
        };

        this.autoSaveInterval = null;
    }

    componentDidMount() {
        // Listen for browser offline/online events

        const database = getDatabase(app);
        const areaStatePath = ref(
            database,
            `schools/${this.props.schoolData.path}/submissions/${this.props.area.path}/${this.props.user.uid}`
        );

        // Listen for changes in Firebase to set initial or updated values
        onValue(
            areaStatePath,
            (snapshot) => {
                const data = snapshot.val() || {};
                if (data.values) {
                    // If not already loaded, treat them as initial values
                    if (!this.state.loaded) {
                        this.setState({
                            initialValues: data.values,
                            loaded: true,
                            databaseValues: data,
                            mark: data.mark || null,
                            visibleStep: data.step || 0,
                            loadError: false
                        });
                    } else {
                        // If we are already loaded, just update databaseValues
                        // (don't reset Formik unless you want live external updates to reflect)
                        this.setState({
                            databaseValues: data,
                            mark: data.mark || null,
                            visibleStep: data.step || 0
                        });
                    }
                } else {
                    // data has no 'values' yet
                    if (!this.state.loaded) {
                        this.setState({
                            loaded: true,
                            databaseValues: data,
                            mark: data.mark || null,
                            visibleStep: data.step || 0,
                            initialValues: {},
                            loadError: false
                        });
                    } else {
                        this.setState({
                            databaseValues: data,
                            mark: data.mark || null,
                            visibleStep: data.step || 0
                        });
                    }
                }
            },
            (error) => {
                console.error("Failed to read data from Firebase:", error);
                this.setState({ loadError: true, loaded: true });
            }
        );

        // Start auto-save checks every 10 seconds
        this.autoSaveInterval = setInterval(this.autoSave, 10000);
    }

    componentWillUnmount() {

        if (this.autoSaveInterval) {
            clearInterval(this.autoSaveInterval);
        }
    }


    /**
     * This runs every 10s, checks if we have 'autoSavedValues' and 'valuesChanged'
     * If we do, we do a partial update of *only* changed keys to Firebase.
     */
    autoSave = () => {
        const { autoSavedValues, valuesChanged, databaseValues } = this.state;

        if (!autoSavedValues || !valuesChanged) {
            return;
        }

        const previousValues = databaseValues.values || {};
        const changedKeys = [];

        // Gather only keys whose values differ from the last DB snapshot
        for (const key in autoSavedValues) {
            if (autoSavedValues.hasOwnProperty(key)) {
                if (autoSavedValues[key] !== previousValues[key] &&
                    autoSavedValues[key] !== ""
                ) {
                    changedKeys.push(key);
                }
            }
        }

        if (changedKeys.length === 0) {
            // If we detect no actual difference, just reset 'valuesChanged' 
            this.setState({ valuesChanged: false });
            return;
        }

        // We'll create a small 'updates' object for partial updates:
        const updates = {};
        changedKeys.forEach((key) => {
            updates[`values/${key}`] = autoSavedValues[key];
        });

        // We also want to preserve 'mark' and 'step' if they exist, so let's write them if needed:
        if (databaseValues.mark) {
            updates["mark"] = databaseValues.mark;
        }
        if (databaseValues.step !== undefined) {
            updates["step"] = databaseValues.step;
        }

        const db = getDatabase(app);
        const submissionPath = ref(
            db,
            `schools/${this.props.schoolData.path}/submissions/${this.props.area.path}/${this.props.user.uid}`
        );

        // Manual 5s timer to detect slow/no response from server
        const offlineTimer = setTimeout(() => {
            console.log("No immediate response from Firebase; possible offline or slow connection.");
            this.setState({ possibleOffline: true });
        }, 5000);

        // Use 'update' for partial writes
        update(submissionPath, updates)
            .then(() => {
                clearTimeout(offlineTimer);
                this.setState({ valuesChanged: false, errorSaving: false, possibleOffline: false });
                console.log("Partial auto-save successful:", updates);
            })
            .catch((error) => {
                clearTimeout(offlineTimer);
                console.error("Partial auto-save failed:", error);
                // Usually occurs if rules reject or the browser can't queue it at all
                this.setState({ errorSaving: true, possibleOffline: false });
            });
    };

    /**
     * For demonstration, we keep your old approach of updating "autoSavedValues"
     * and "valuesChanged" from Formik's 'validate' to detect changed values.
     */
    validateForm = (values) => {
        // If the user changes any field from what's in DB, set them to autoSavedValues
        if (values !== this.state.databaseValues.values) {
            this.setState({ autoSavedValues: values, valuesChanged: true });
        }
        // Return {} so Formik won't block submission
        return {};
    };


    render() {
        const {
            loaded,
            loadError,
            errorSaving,
            possibleOffline,
            valuesChanged,
            initialValues,
            databaseValues,
            visibleStep
        } = this.state;

        const characteristicsData = this.props.area.characteristics.sort((a, b) => a.index - b.index);

        // Only show characteristics for the active step (if one is specified)
        const characteristics = characteristicsData
            .map((char, originalIdx) => ({ ...char, originalIdx })) 
            .filter((char) => char.step === undefined || char.step === visibleStep)
            .map((char) => {
                return (
                    <Characteristic
                        key={char.id}
                        schoolData={this.props.schoolData}
                        user={this.props.user}
                        area={this.props.area}
                        values={this.state.autoSavedValues || initialValues}
                        index={char.originalIdx} 
                        characteristicData={char}
                        graceData={
                            databaseValues.grace ? databaseValues.grace[char.id] : null
                        }
                    />
                );
            });


        if (loadError) {
            return (
                <div className="alert alert-danger" style={{ marginBottom: "1rem" }}>
                    Unable to load. Please check your connection.
                </div>)

        } else {
            return (
                <div>


                    {/* FIREBASE READ ERROR (e.g. security rules) */}
                    {loadError && (
                        <div className="alert alert-danger" style={{ marginBottom: "1rem" }}>
                            Unable to load. Please check your connection.
                        </div>
                    )}

                    {/* EXPLICIT WRITE FAILURE (security rules, etc.) */}
                    {errorSaving && (
                        <div
                            className="alert alert-danger"
                            style={{ position: "fixed", top: "2rem", left: "2rem", zIndex: 999 }}
                        >
                            <h6>Save failed. </h6>
                        </div>
                    )}

                    {/* POSSIBLE OFFLINE / SLOW RESPONSE */}
                    {possibleOffline && !errorSaving && (
                        <div
                            className="alert alert-danger"
                            style={{ position: "fixed", top: "2rem", left: "2rem", zIndex: 999 }}
                        >
                            <h6>
                                Something went wrong, we couldn’t save your work. <br />
                                You might not be connected to the internet. <br /><br />
                                Your work will be saved automatically when you’re back online.
                            </h6>
                        </div>
                    )}

                    {/* SHOW "SAVING" WHEN VALUES JUST CHANGED */}
                    {valuesChanged && !possibleOffline && !errorSaving && (
                        <div
                            className="alert alert-warning"
                            style={{ position: "fixed", top: "2rem", left: "2rem", zIndex: 999 }}
                        >
                            <h6>Saving...</h6>
                        </div>
                    )}


                    {/* LOADING INDICATOR */}
                    {!loaded && <p>Loading...</p>}

                    {/* MAIN FORM */}
                    {loaded && (
                        <Formik
                            initialValues={initialValues}
                            validate={this.validateForm}
                            onSubmit={(values) => {
                                console.log("Form submitted (manual submit):", values);
                            }}
                        >
                            {({ values, handleChange, handleSubmit }) => (
                                <Form onSubmit={handleSubmit}>
                                    <div style={{ marginTop: "2rem" }} className="row">
                                        <div className="col-12 col-lg-6">
                                            <AreaSteps
                                                schoolData={this.props.schoolData}
                                                area={this.props.area}
                                                values={databaseValues}
                                                user={this.props.user}
                                                getStep={this.state}
                                            />
                                        </div>
                                        <div
                                            className="col-12 col-lg-6"
                                            style={{ textAlign: "right", marginBottom: "1rem" }}
                                        >
                                        </div>
                                    </div>


                                    {/* Render the characteristics */}
                                    {characteristics}
                                </Form>
                            )}
                        </Formik>
                    )}

                    {/* Optional repeated step navigation at the bottom */}
                    <AreaSteps
                        schoolData={this.props.schoolData}
                        area={this.props.area}
                        values={databaseValues}
                        user={this.props.user}
                        getStep={this.state}
                    />
                </div>
            );

        }

    }
}

export default Characteristics;
