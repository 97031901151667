
const firebaseErrorMessages = {
    "auth/invalid-email": "The email address is not valid. Please enter a valid email.",
    "auth/user-disabled": "This account has been disabled. ",
    "auth/user-not-found": "No account found with this email. Check your email address is correct or sign up first.",
    "auth/wrong-password": "Incorrect password.",
    "auth/email-already-in-use": "This email is already in use. Please use a different email or sign in to your existing account",
    "auth/operation-not-allowed": "This operation is not allowed. Please contact support.",
    "auth/weak-password": "Your password is too weak. Please use a stronger password.",
    "auth/network-request-failed": "Network error. Please check your connection and try again.",
    "auth/too-many-requests": "Too many attempts. Please try again later.",
    "auth/requires-recent-login": "Please log in again to perform this operation. Contact support if this appears unusual",
    "auth/credential-already-in-use": "This account is already linked with another user.",
    default: "An unexpected error occurred. Please try again and contact support if this error persists.",
};

export default firebaseErrorMessages;
