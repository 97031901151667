import React from "react";

import { Formik, Field, Form, ErrorMessage } from "formik";
import { createUserWithEmailAndPassword, getAuth } from "firebase/auth";
import { getDatabase, ref, set, onValue } from "firebase/database";
import app from '../firebase.js'
//import { firebase_app } from 'firebase';

import firebaseConfig from "../firebaseConfig.js"


class CharlieSignUp extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            submitAttempt: false,
            values: {}

        }
    }

    componentDidMount() {



    }

    signUp = () => {



        const email = this.state.values.email

        createUserWithEmailAndPassword(getAuth(app), email, this.state.values.password)
            .then((userCredential) => {
                // Signed in 
                const user = userCredential.user;

                this.setState({ user: user })
                this.createUserInDatabase(user)

                console.log(this.state.user)
                // ...
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;

                console.log(errorMessage)
                // ..
            });
    }

    createUserInDatabase = (user) => {
        const database = getDatabase(app)
        const inSchoolPath = ref(database, 'schools/' + 'mrCharlieBot' + '/students/' + user.uid);
        const userPath = ref(database, 'users/' + user.uid);

        set(inSchoolPath, {
            details: {
                firstName: this.state.values.firstName,
                lastName: this.state.values.lastName,
                dob: this.state.values.dob,
                mrCharlieBot: true
            },
        });

        set(userPath, {
            role: "student",
            schools: {
                mrCharlieBot: true,
            }
        });
    }


    render() {
        return (
            <div style={{ textAlign: "center", padding: "2rem" }}>
                <h3 className="mb-8 display-8 text-primary">
                    Sign up to
                </h3>
                <img style={{ height: "16vh" }} src="https://jaj.dfq.mybluehost.me/website_5857b8b4/wp-content/uploads/2024/12/Copy-of-Charlie-Bot.png" />


                <div className="card shadow-lg" style={{ textAlign: "left", padding: "2rem", maxWidth: "700px", margin: "0 auto" }}>
                    <Formik
                        initialValues={{}}
                        onSubmit={async values => {
                            await new Promise(resolve => setTimeout(resolve, 500));
                            if (Object.keys(this.state.errors).length === 0) {
                                console.log("submit");
                                this.setState({ submitAttempt: true, values: values }, () => {
                                    this.signUp();
                                });
                            } else {
                                console.log("invalid fields, this is my state", this.state.errors);
                                this.setState({ submitAttempt: true });
                            }
                        }}
                        validate={values => {
                            const errors = checkForInvalidFields(values);
                            this.setState({ errors: errors });
                        }}
                    >
                        <Form>
                            <h4 className="form-heading mb-4 text-dark">Student login details</h4>

                            {JSON.stringify(this.props?.user)}
                            <div className="row mb-3">
                                <div className="col-lg-6 col-12">
                                    <label>Family name</label>
                                    <Field
                                        name="lastName"
                                        type="text"
                                        className={`${this.state.errors.lastName && this.state.submitAttempt ? "is-invalid" : ""} form-control`}
                                        placeholder=" "
                                    />
                                    <div className="invalid-feedback">{this.state.errors.lastName}</div>
                                </div>
                                <div className="col-lg-6 col-12">
                                    <label>Given Name</label>
                                    <Field
                                        name="firstName"
                                        type="text"
                                        className={`${this.state.errors.firstName && this.state.submitAttempt ? "is-invalid" : ""} form-control`}
                                    />
                                    <div className="invalid-feedback">{this.state.errors.firstName}</div>
                                </div>



                            </div>
                            <div className="row mb-3">
                                <div className="col-lg-12 col-12">
                                    <label>Date of Birth</label>
                                    <Field
                                        name="dob"
                                        type="date"
                                        className={`${this.state.errors.dob && this.state.submitAttempt ? "is-invalid" : ""} form-control`}
                                    />
                                    <div className="invalid-feedback">{this.state.errors.firstName}</div>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-lg-6 col-12">
                                    <label>Email Address</label>
                                    <Field
                                        name="email"
                                        type="email"
                                        className={`${this.state.errors.email && this.state.submitAttempt ? "is-invalid" : ""} form-control`}
                                        placeholder=" "
                                    />
                                    <div className="invalid-feedback">{this.state.errors.email}</div>
                                </div>
                                <div className="col-lg-6 col-12">
                                    <label>Confirm Email Address</label>
                                    <Field
                                        name="confirmEmail"
                                        onPaste={(e) => e.preventDefault()}
                                        type="email"
                                        className={`${this.state.errors.confirmEmail && this.state.submitAttempt ? "is-invalid" : ""} form-control`}
                                        placeholder=" "
                                    />
                                    <div className="invalid-feedback">{this.state.errors.confirmEmail}</div>
                                </div>
                            </div>
                            <div className="row mb-4">
                                <div className="col-lg-6 col-12">
                                    <label>Create Password</label>
                                    <Field
                                        name="password"
                                        type="password"
                                        className={`${this.state.errors.password && this.state.submitAttempt ? "is-invalid" : ""} form-control`}
                                        placeholder=" "
                                    />
                                    <div className="invalid-feedback">{this.state.errors.password}</div>
                                </div>
                                <div className="col-lg-6 col-12">
                                    <label>Confirm Password</label>
                                    <Field
                                        name="confirmPassword"
                                        type="password"
                                        className={`${this.state.errors.confirmPassword && this.state.submitAttempt ? "is-invalid" : ""} form-control`}
                                        onPaste={(e) => e.preventDefault()}
                                        placeholder=" "
                                    />
                                    <div className="invalid-feedback">{this.state.errors.confirmPassword}</div>
                                </div>
                            </div>

                            <p>
                                By signing up, you agree to our &nbsp;
                                <a href="https://mrcharliebot.com/terms" target="_blank" rel="noopener noreferrer">Terms of Service</a>&nbsp;
                                and &nbsp;
                                <a href="https://mrcharliebot.com/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>.
                            </p>


                            <button className="btn btn-primary w-100" type="submit">
                                Submit
                            </button>
                        </Form>
                    </Formik>
                </div>
            </div>
        );
    }

}

export default CharlieSignUp;



function checkForInvalidFields(values, stage) {
    const errors = {};

    if (!values.firstName) {
        errors.firstName = 'Required';
    }
    if (!values.lastName) {
        errors.lastName = 'Required';
    }

    if (!values.dob) {
        errors.dob = 'Required';
    }

    if (stage === "primary") {
        if (!values.email) {
            errors.email = 'Required';
        } else if (!/^[a-zA-Z0-9_]*$/.test(
            values.email
        )) {
            errors.email = 'Invalid username. Only use letters and numbers. No spaces';
        }

    } else {

        if (!values.email) {
            errors.email = 'Required';
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
            values.email
        )) {
            errors.email = 'Invalid email address';
        }

    }
    if (values.email != values.confirmEmail) {
        errors.confirmEmail = 'Does not match';
    }

    if (!values.password) {
        errors.password = 'Required';
    } else if (values.password.length < 6) {

        errors.password = 'Password must be at least 6 characters in length';

    }


    if (values.password != values.confirmPassword) {
        errors.confirmPassword = 'Password does not match';
    }

    return errors;
}
