import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

const styles = `
  .chat-display-container {
    height: 80vh;
    overflow-y: auto;
    padding: 20px;
    color: white;
    font-family: Arial, sans-serif;
    border-radius: 10px;
    box-sizing: border-box;
    scrollbar-width: thin;
    scrollbar-color: #007bff #343a40;
  }
  .chat-display-container::-webkit-scrollbar {
    width: 8px;
  }
  .chat-display-container::-webkit-scrollbar-thumb {
    background-color: #007bff;
    border-radius: 10px;
  }
  .chat-display-container::-webkit-scrollbar-track {
    background: #343a40;
  }
  .chat-message {
    margin-bottom: 15px;
    display: flex;
  }
  .user-message {
    justify-content: flex-end;
  }
  .assistant-message {
    justify-content: flex-start;
  }
  .message-bubble {
    max-width: 70%;
    padding: 15px;
    border-radius: 20px;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
    word-wrap: break-word;
    overflow-wrap: break-word;
  }
  .user-message-bubble {
    background: #007bff;
    color: white;
    border-bottom-right-radius: 0;
  }
  .assistant-message-bubble {
    background: #6c757d;
    color: white;
    border-bottom-left-radius: 0;
  }
  .message-content {
    margin: 0;
    font-size: 1rem;
  }
  .no-messages {
    text-align: center;
    color: #ccc;
    font-size: 1.2rem;
  }
  .chat-input-container {
    display: flex;
    padding: 15px;
    border-top: 1px solid #555;
  }
  .chat-input {
    flex: 1;
    padding: 10px;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    margin-right: 10px;
  }
  .send-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  .send-button:hover {
    background-color: #0056b3;
  }
`;
const ChatDisplay = ({ conversationHistory, onSendMessage, processing }) => {
  const [inputValue, setInputValue] = useState("");
  const [sentChat, setSentChat] = useState("");
  const [localConversationHistory, setLocalConversationHistory] = useState(
    conversationHistory
  );

  useEffect(() => {
    // Update the local conversation history only when processing is false
    if (!processing) {
      setLocalConversationHistory(conversationHistory);
      setSentChat("")
    }
  }, [conversationHistory, processing]);

  const handleSendMessage = () => {
    if (inputValue.trim() && !processing) {
      setSentChat(inputValue);
      onSendMessage(inputValue); // Call the parent function
      setInputValue(""); // Clear the input
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter" && !processing) {
      handleSendMessage();
    }
  };

  return (
    <div>
      <style>{styles}</style>
      <div className="chat-display-container">
        {localConversationHistory && localConversationHistory.length > 0 ? (
          localConversationHistory.map((message, index) => (
            <div
              key={index}
              className={`chat-message ${
                message.role === "user" ? "user-message" : "assistant-message"
              }`}
            >
              <div
                className={`message-bubble ${
                  message.role === "user"
                    ? "user-message-bubble"
                    : "assistant-message-bubble"
                }`}
              >
                <p className="message-content">{message.content}</p>
              </div>
            </div>
          ))
        ) : (
          <p className="no-messages">No conversation history available</p>
        )}

        {/* Render sentChat only if processing */}
        {processing && sentChat && (
          <div className="chat-message user-message">
            <div className="message-bubble user-message-bubble">
              <p className="message-content">{sentChat}</p>
            </div>
          </div>
        )}
      </div>
      <div className="chat-input-container">
        <input
          type="text"
          className="chat-input"
          placeholder="Type a message..."
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyPress={handleKeyPress}
          disabled={processing} // Disable input when processing
        />
        <button
          className="send-button"
          onClick={!processing ? handleSendMessage : null}
          disabled={processing} // Disable button when processing
        >
          {processing ? "Thinking..." : "Send"}
        </button>
      </div>
    </div>
  );
};

ChatDisplay.propTypes = {
  conversationHistory: PropTypes.arrayOf(
    PropTypes.shape({
      role: PropTypes.oneOf(["user", "assistant"]).isRequired,
      content: PropTypes.string.isRequired,
    })
  ).isRequired,
  onSendMessage: PropTypes.func.isRequired, // Function passed as a prop
  processing: PropTypes.bool.isRequired,
};

export default ChatDisplay;
