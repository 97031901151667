import React, { useState } from "react";
import characters from "./characters.json";
import CharacterChat from "./characterChat"; // Assuming CharacterChat is a separate component
import StudentNavBar from "../student/navBar.js";

const CharactersDisplay = ({ user, games }) => {
    const [selectedSituation, setSelectedSituation] = useState(null);
    const [isChatting, setIsChatting] = useState(false);
    const [selectedSituationInded,setSelectedSituationIndex]=useState(null)

    const handleOnClose = () => {
        setSelectedSituation(null);
        setIsChatting(false);
    };

    if (isChatting && selectedSituation) {
        return (
            <CharacterChat
                user={user}
                character={selectedSituation.character}
                situation={selectedSituation.situation}
                onClose={handleOnClose}
            />
        );
    }

    return (
        <div className="container py-4">
            <StudentNavBar user={user} schoolData={{ path: "mrCharlieBot", name: "mrcharliebot" }} charliebot={true} />
            <h1 className="text-center text-primary mb-5">{games ? "Games" : "Tasks"}</h1>
            <div className="row g-4 justify-content-center">
                {characters?.flatMap((character) =>
                    character.situations
                        .filter((situation) => (games ? situation.type === "game" : situation.type !== "game"))
                        .map((situation, index) => (
                            <div
                                key={`${character.name}-${index}`}
                                className="col-6 col-sm-4 col-md-3 text-center"
                                onClick={() => setSelectedSituation({ character, situation: index })}
                                style={{ cursor: "pointer" }}
                            >
                                <div className="card shadow border-0 h-100 transition-all duration-300">
                                    <div className="card-body bg-dark">
                                        <div
                                            className="rounded-circle mx-auto mb-3 overflow-hidden"
                                            style={{ width: "100px", height: "100px" }}
                                        >
                                            <img
                                                src={character.image_url}
                                                alt={character.name}
                                                className="img-fluid h-100 w-100 object-fit-cover"
                                            />
                                        </div>
                                        <h5 className="card-title text-primary mb-1">{situation.title}</h5>
                                        <p className="card-text text-white">
                                            {character.name} 
                                        </p>
                                    </div>
                                </div>
                            </div>
                        ))

                )}

                <div
                    className="col-6 col-sm-4 col-md-3 text-center"
                >
                    <div className="card shadow border-0 h-100 transition-all duration-300">
                        <div className="card-body bg-dark">
                            <div
                                className="rounded-circle mx-auto mb-3 overflow-hidden"
                                style={{ width: "100px", height: "100px" }}
                            >
                                <div
                                    className="img-fluid h-100 w-100 object-fit-cover background-secondary"
                                ></div>
                            </div>
                            <h5 className="card-title text-secondary mb-1">More {games ? "games" : "tasks"} coming soon</h5>
                        </div>
                    </div>
                </div>
            </div>


            {selectedSituation && (
                <div
                    className="modal show d-block"
                    tabIndex="-1"
                    role="dialog"
                    style={{ backgroundColor: "rgba(0,0,0,0.5)" }}
                >
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header border-0 bg-dark text-white">
                                <div className="d-flex align-items-center">
                                    <div
                                        className="rounded-circle overflow-hidden me-3"
                                        style={{ width: "50px", height: "50px" }}
                                    >
                                        <img
                                            src={selectedSituation.character.image_url}
                                            alt={selectedSituation.character.name}
                                            className="img-fluid h-100 w-100 object-fit-cover"
                                        />
                                    </div>
                                    <h5 className="modal-title mb-0">
                                        {selectedSituation.situation.title}
                                    </h5>
                                </div>
                                <button
                                    type="button"
                                    className="btn-close btn-close-white"
                                    aria-label="Close"
                                    onClick={() => setSelectedSituation(null)}
                                ></button>
                            </div>
                            <div className="modal-body bg-dark text-white">
                                <p>
                                    {selectedSituation.character.name}
                                </p>
                            </div>
                            <div className="modal-footer border-0 bg-dark">
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={() => setIsChatting(true)}
                                >
                                    Start {games ? "Game" : "Conversation"} 
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            <style>
                {`
                  @keyframes fadeIn {
                    from { opacity: 0; }
                    to { opacity: 1; }
                  }
                  
                  @keyframes fadeOut {
                    from { opacity: 1; }
                    to { opacity: 0; }
                  }
        
                  .card:hover {
                    transform: scale(1.05) !important;
                    box-shadow: 0 10px 20px rgba(0,0,0,0.2) !important;
                  }
        
                  .card:hover img {
                    transform: scale(1.1);
                  }
        
                  .modal-content {
                    border: none;
                    overflow: hidden;
                  }
        
                  .transition-all {
                    transition: all 0.3s ease;
                  }
                `}
            </style>
        </div>
    );
};

export default CharactersDisplay;
