
export const replaceWildcards = (template, wildcards) => {
    if (!template || typeof template !== "string") {
        return ""; // Return an empty string if the template is invalid
    }
    if (!wildcards || typeof wildcards !== "object") {
        wildcards = {}; // Use an empty object if wildcards is null or not an object
    }

    return template.replace(/\{(\w+)\}/g, (match, key) => {
        return key in wildcards ? wildcards[key] : match;
    });
};
